import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storage: Storage = localStorage;
  private prefix: string = '';

  setPrefix(prefix: string): void {
    this.prefix = prefix;
  }

  setStorageType(storageType: 'local' | 'session'): void {
    this.storage = storageType === 'local' ? localStorage : sessionStorage;
  }  

  // Set data in storage
  set(key: string, data: any): void {
    try {
      const serializedData = JSON.stringify(data);
      this.storage.setItem(this.prefixedKey(key), serializedData);
    } catch (e) {
      console.error('Error saving to storage', e);
    }
  }

  // Get data from storage
  get(key: string): any {
    try {
      const data = this.storage.getItem(this.prefixedKey(key));
      if (data == undefined || data == null) {
        return null;
      }
      return JSON.parse(data);
    } catch (e) {
      console.error('Error reading from storage', e);
      return null;
    }
  }

  // Clear data from storage based on regex pattern
  clear(pattern: RegExp): void {
    try {
      const fullPattern = new RegExp(this.prefix + pattern.source);
      Object.keys(this.storage).forEach(key => {
        if (fullPattern.test(key)) {
          this.storage.removeItem(key);
        }
      });
    } catch (e) {
      console.error('Error clearing storage', e);
    }
  }

  // List all keys in storage without the prefix
  keys(): string[] {
    return Object.keys(this.storage)
      .filter(key => key.startsWith(this.prefix))
      .map(key => key.substring(this.prefix.length));
  }

  // Helper method to add prefix to a key
  private prefixedKey(key: string): string {
    return `${this.prefix}${key}`;
  }
}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MessageService } from '../Services/message.service';
import { BackendService } from '../Services/backend.service';
import { WisApiService } from '../Services/wisapi.service';
import { GlobalService } from '../Services/global.service';
import { DataService } from '../Services/data.service';
import { SurveyService } from '../Services/survey.service';
import { ChangeDetectorRef } from '@angular/core';
import {
  AvailableSurveys, AvailableSurveyDetails, AvailableSurveyPresets
} from '../Models/available-survey';
import { Logger } from '../Services/logger.service';
import { KeyValuePair } from '../Models/keyvalue';

@Component({
  selector: 'settings',
  templateUrl: '../Templates/settings.component.html',
  // styleUrls: ['../Styles/survey.component.scss']
})
export class SettingsComponent implements OnInit {

  @Output() changed = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();

  public availableSurveys: AvailableSurveys = null;
  public activeSurvey: AvailableSurveyDetails = null;
  private _selectedSurvey: string;
  public selectedLocale: string;
  public selectedTranslation: string;
  public selectedReferrer: string;
  public selectedReferrer2: string;
  public params: KeyValuePair[] = [];
  public toggleDetailedSettings = 1;
  private sessionId: null;

  constructor(
    public globals: GlobalService,
    private messageService: MessageService,
    private backendService: BackendService,
    private surveyService: SurveyService,
    private logger: Logger,
    private wisApiService: WisApiService,
    private dataService: DataService,  
    private cdr: ChangeDetectorRef
  ) {

    backendService.getAvailableSurveys().subscribe((surveys) => {
      this.availableSurveys = surveys;
      this.selectActiveSurvey();
    },
      (error) => {
        this.logger.error(error);
      });
  }

  public clickSettings() {
    this.toggleDetailedSettings += 1;
    this.toggleDetailedSettings = this.toggleDetailedSettings % 5;
  }

  public closeSettings() {
    this.close.emit(true);
  }

  public ngOnInit() {
    this._selectedSurvey = this.globals.surveyId;
    this.selectedLocale = this.globals.locale;
    this.selectedTranslation = this.globals.getDisplayLocale();
    this.selectedReferrer = this.globals.referrer;
    this.selectedReferrer2 = this.globals.referrer2;
  }

  private selectActiveSurvey() {
    this.activeSurvey = null;
    const selectedId = this._selectedSurvey || this.globals.surveyId;
    for (const surv of this.availableSurveys.surveys) {
      if (surv.id === selectedId) {
        this.activeSurvey = surv;
        this.cdr.markForCheck();
        break;
      }
    }
  }

  public loadPreset(preset) {
    this.closeSettings();
    this.surveyService.startInitializing();

    setTimeout(() => {
      this._selectedSurvey = null;
      this.selectedLocale = null;
      this.selectedTranslation = null;
      this.selectedReferrer = null;
      this.selectedReferrer2 = null;

      const mapping = {
        'surveyId': '_selectedSurvey',
        'locale': 'selectedLocale',
        'displayLocale': 'selectedTranslation',
        'referrer': 'selectedReferrer',
        'referrer2': 'selectedReferrer2',
      };

      for (const param of preset.params) {
        if (param.name in mapping) {
          this[mapping[param.name]] = param.value;
        }
      }

      // now activate the new preset.. should we do this automatically now?
      this.changeSurveySettings(true);
      // this.surveyService.finishInitializing();

    }, 0);

    return false; // to prevent default click
  }

  public addEmptyParam() {
    this.params.push({} as KeyValuePair);
  }

  public set selectedSurvey(value: string) {
    this._selectedSurvey = value;
    this.selectActiveSurvey();
  }
  public get selectedSurvey(): string {
    return this._selectedSurvey;
  }

  public changeSurveySettings(clear: boolean) {
    // update the global settings, then reload the survey

    this.globals.surveyId = this.selectedSurvey;
    this.globals.locale = this.selectedLocale;
    this.globals.setDisplayLocale(this.selectedTranslation);
    this.globals.referrer = this.selectedReferrer;
    this.globals.referrer2 = this.selectedReferrer2;

    const qp = {};
    for (const kv of this.params) {
      if (kv.key === 'offline') {
        this.globals.OFFLINE = !!kv.value;
      } else if (kv.key === 'debug') {
        this.globals.DEBUG = !!kv.value;
      } else {
        qp[kv.key] = kv.value;
      }
    }

    if (clear) {
      this.dataService.restartSurvey();
    }

    this.surveyService.initSurvey(this.globals.surveyId, this.globals.locale,
      this.globals.getDisplayLocale(),
      this.globals.referrer, this.globals.referrer2,
      this.sessionId, qp, null);

    this.changed.emit(true);
  }

  public clearLocalStorage() {
    // clear the wisapi + rest localstorage
    // this will NOT clear the stored surveys, they should be uploaded

    this.backendService.clearLocalStorage();
    this.wisApiService.clearLocalStorage();

    this.messageService.showMessage(
      'info',
      'Searchtrees + Survey model cache removed. Please restart the survey'
    );
  }

}

<nav *ngIf="showNavbar()" class="submission navbar navbar-light my-3 p-3 border rounded bg-light shadow-sm">

  <button *ngIf="pgc.hasPreviousPage() && (!pgc.isLastPage() || pgc.allowPreviousPage())"
          class="btn btn-outline-secondary nav-prev me-2"
          type="button"
          role="button"
          (click)="pgc.previousPage($event)">
    <fa-icon [icon]="['fas', 'angle-double-left']"></fa-icon>
  </button>

  <button *ngIf="pgc.hasNextPage()" href
          class="btn btn-outline-primary btn-wsnav nav-next" role="button"
          type="button"
          [ngClass]="getPageStatus() + ' ' + pgc.getNextButtonExtraClasses()"
          (click)="pgc.nextPage($event)">
    {{ pgc.getNextLabel() | wsTrans: pgc.getNextMsgId() |async }}
    <fa-icon [icon]="['fas', 'angle-double-right']"></fa-icon>
  </button>

  <app-ws-offline-restart></app-ws-offline-restart>

  <ng-container *ngIf="globals.OFFLINE">
  <small *ngIf="globals.referrer" class="badge badge-info float-right">{{ globals.referrer }}</small>
  <small *ngIf="globals.referrer2" class="badge badge-info float-right">{{ globals.referrer2 }}</small>
  <small class="badge badge-info float-right me-1">{{ globals.surveyId }}</small>
  <small class="badge badge-primary float-right me-1">APP</small>
  </ng-container>

  <app-ws-messages></app-ws-messages>
</nav>
